<template>
  <b-form-group
    label="Business Images"
    label-for="business-images"
  >
    <b-form-file
      id="business-images"
      multiple
      accept="image/*"
      @input="onSelectImages"
    />
  </b-form-group>
</template>
<script>
import convertImagesToBase64 from '@/common/compositions/common/convertImagesToBase64'

export default {
  name: 'UploadBusinessImages',
  props: {
    images: { type: Array, default: () => [] },
  },
  setup() {
    const {
      handleImages,
      base64Images,
    } = convertImagesToBase64()

    return {
      handleImages,
      base64Images,
    }
  },
  methods: {
    onSelectImages(files) {
      this.handleImages(files)
      this.base64Images.push(...this.images)
      this.$emit('update:images', this.base64Images)
    },
  },
}
</script>
<style lang="scss">

</style>
