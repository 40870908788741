<template>
  <div>
    <b-form-row>
      <!-- Location -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Address"
            label-for="location"
          >
            <input
              id="location"
              ref="locationRef"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Enter Address To Search"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="street"
          rules="required"
        >
          <b-form-group
            label="Street"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="details.street"
              :state="getValidationState(validationContext)"
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="city"
          rules="required"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="details.city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="state"
          rules="required"
        >
          <b-form-group
            label="Province / State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="details.state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="country"
          rules="required"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="details.country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="Postal Code"
          rules="required"
        >
          <b-form-group
            label="Zip / Postal Code"
            label-for="postalCode"
          >
            <b-form-input
              id="postalCode"
              v-model="details.postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="latitude"
          rules="required"
        >
          <b-form-group
            label="Latitude"
            label-for="latitude"
          >
            <b-form-input
              id="latitude"
              v-model="details.lat"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Latitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="longitude"
          rules="required"
        >
          <b-form-group
            label="Longitude"
            label-for="longitude"
          >
            <b-form-input
              id="longitude"
              v-model="details.lng"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Longitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import locationAutoComplete from '@/common/compositions/common/locationAutoComplete'

export default {
  name: 'LocationInputs',
  props: {
    details: { type: Object, default: () => {} },
    inputColumns: { type: Number, default: 3 },
  },
  mounted() {
    this.initAutocomplete(this.details)
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      initAutocomplete,
      locationRef,
    } = locationAutoComplete()
    return {
      initAutocomplete,
      locationRef,
      getValidationState,
    }
  },
}
</script>
<style lang="scss">

</style>
