<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <!-- BODY -->
    <form-wizard
      color="#00A5AF"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="handleSubmit(addBusiness)"
    >
      <tab-content
        :before-change="()=>{
          if($refs.commercialDetails.$data.flags.invalid)
            $refs.commercialDetails.handleSubmit();
          return !$refs.commercialDetails.$data.flags.invalid
        }"
        title="Commercial Details"
      >
        <validation-observer
          ref="commercialDetails"
        >
          <commercial-details
            :commercial-details="businessData"
            :donation-categories="donationCategories"
            :classifications="trimmedClassifications"
            :sub-classifications="subClass"
            :languages="languages"
          />
        </validation-observer>
        <div class="btn-back">
          <back />
        </div>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.commercialSocial.$data.flags.invalid)
            $refs.commercialSocial.handleSubmit();
          return !$refs.commercialSocial.$data.flags.invalid
        }"
        title="Social"
      >
        <validation-observer
          ref="commercialSocial"
        >
          <commercial-social
            :commercial-details="businessData"
            :tag-options="tags"
          />
        </validation-observer>
      </tab-content>

      <upload-progress :progress="progress" />
    </form-wizard>
  </validation-observer>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import commercialDetails from '@/common/components/Business/Add/CommercialDetails.vue'
import commercialSocial from '@/common/components/Business/Add/CommercialSocial.vue'
import UploadProgress from '@/common/components/common/Organizations/UploadProgress.vue'

import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/Back.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSocial,
    UploadProgress,
    Back,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      autocomplete: '',
      place: '',
      businessData: {
        name: '',
        lat: '',
        lng: '',
        logo: '',
        cover: '',
        about: '',
        phone_number: '',
        email: '',
        social_facebook: '',
        social_twitter: '',
        social_instagram: '',
        social_youtube: '',
        website: '',
        class_id: '',
        sub_class_id: '',
        address_type: 1,
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        language_id: '',
        tag_id: '',
        business_type: '',
        phoneNum: '',
        media_files: '',
      },
    }
  },
  computed: {
    trimmedClassifications: {
      get() {
        return this.classifications.filter(element => element.name !== 'Organization')
      },
    },
    subClass: {
      get() {
        return this.$store.getters['generalSettings/getSubClassifications']
      },
    },
    classID: {
      get() {
        return this.businessData.class_id
      },
    },
  },
  watch: {
    classID(classID) {
      this.$store.dispatch('generalSettings/getClassSubClassificationRequest', classID)
    },
  },
  created() {
    this.resetSubClasses()
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const { formData, setFormData } = handleFormData()
    const {
      languages,
      tags,
      donationCategories,
      classifications,
    } = getOrganizationDetails()

    const {
      successfulOperationAlert,
      showErrors,
    } = handleAlerts()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      languages,
      tags,
      donationCategories,
      classifications,
      formData,
      setFormData,
      successfulOperationAlert,
      showErrors,
      progress,
      calculateUploadPercentage,
    }
  },
  methods: {
    resetSubClasses() {
      this.$store.commit('generalSettings/setSubClass', [])
    },
    addBusiness() {
      this.swapLatAndLong()
      this.setFormData(this.businessData)
      this.$entities
        .post('internalops/commercial_entity', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        }).then(() => {
          this.successfulOperationAlert('Business is added successfully')
          this.$router.push('/business/list')
        }).catch(errors => {
          this.showErrors(errors.response.data.errors)
        })
    },
    swapLatAndLong() {
      [this.businessData.lat, this.businessData.lng] = [this.businessData.lng, this.businessData.lat] // this is because api reverse them
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
