import { ref } from '@vue/composition-api'

export default function convertImagesToBase64() {
  const base64Images = ref([])

  const createBase64Image = image => {
    const reader = new FileReader()
    reader.onload = e => {
      base64Images.value.push(e.target.result)
    }
    reader.readAsDataURL(image)
  }

  const handleImages = images => {
    base64Images.value = []
    images.forEach(image => {
      createBase64Image(image)
    })
  }

  return {
    handleImages,
    base64Images,
  }
}
