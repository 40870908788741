<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required|min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="commercialDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"

          rules="required|min:6|max:14"
          name="Mobile Number"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="commercialDetails.phone_number"
              :state="getValidationState(validationContext)"
              type="number"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="commercialDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="commercialDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-form-row class="my-2">
      <!-- Classification -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="Classification"
          rules="required"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="commercialDetails.class_id"
              :options="classifications"
              value-field="id"
              text-field="name"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  -- Please select an option --
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="Sub Classification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="commercialDetails.sub_class_id"
              :options="subClassifications"
              value-field="id"
              text-field="name"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  -- Please select an option --
                </b-form-select-option>
              </template>

            </b-form-select>
            <template #first>
              <option value="null">
                Please Select
              </option>
            </template>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Language -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="Language"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="commercialDetails.language_id"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <location-inputs :details="commercialDetails" />

    <organization-upload-images
      class="my-3"
      :logo.sync="commercialDetails.logo"
      :cover.sync="commercialDetails.cover"
      :delete-enabled="false"
    />
    <b-form-row>
      <b-col lg="6">
        <upload-business-images :images.sync="commercialDetails.media_files" />
      </b-col>
      <b-col cols="12">
        <template
          v-for="(image,index) in commercialDetails.media_files"
          class="mb-2"
        >
          <b-img
            v-if="!isImageBase64(image.url || image)"
            :key="index"
            height="100"
            width="100"
            :src="`${$entitiesImagesURL}/${image.url}`"
            class="ml-1 mb-1 pointer-cursor"
            @click="removeImageByIndex(index)"
          />
          <b-img
            v-else
            :key="index"
            height="100"
            width="100"
            :src="image"
            class="ml-1 mb-1 pointer-cursor"
            @click="removeImageByIndex(index)"
          />
        </template>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import organizationUploadImages from '@/common/components/common/Organizations/organizationUploadImages.vue'
import UploadBusinessImages from '@/common/components/Business/UploadBusinessImages.vue'
import LocationInputs from '@/common/components/common/LocationInputs.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  components: {
    organizationUploadImages,
    UploadBusinessImages,
    vSelect,
    LocationInputs,
  },
  props: {
    commercialDetails: {
      type: Object,
      default: () => {},
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { confirmDelete } = handleAlerts()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      confirmDelete,
    }
  },
  methods: {
    isImageBase64(image) {
      return image?.includes('data:image')
    },
    removeImageByIndex(index) {
      this.confirmDelete().then(() => {
        this.commercialDetails.media_files.splice(index, 1)
      })
    },
  },
}
</script>

<style lang="scss">

</style>
